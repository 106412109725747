<template>
  <div>
    <doctor-table
      :canDelete="true"
      viewRoute="FranchiseDoctorEdit"
      :canCreate="true"
      createRoute="FranchiseDoctorCreate"
      viewDetailRoute="FranchiseDoctorDetailView"
      :canRequestAssociation="true"
    />
  </div>
</template>

<script>
import doctorTable from "../../components/doctors/doctorTable.vue";

export default {
  components: {
    doctorTable
  },
  data: () => ({})
};
</script>